import DateUtility from 'helpers/date-helper';
import { DocumentEntity } from 'types/document-types';

const initialDocument: DocumentEntity = {
  id: '',
  date_entered: DateUtility.getTodayDateString(),
  date_modified: '',
  modified_user_id: '',
  document_name: '',
  pdf_template_name: '',
  active_date: '',
  category_id: '',
  status_id: '',
  document_revision_id: '',
  opportunity_id: '',
  document_subtype: '',
  document_state: '',
  document_type: '',
  revision: '',
  document_status: '',
  document_revision: {
    id: '',
    change_log: '',
    filename: '',
    file_ext: '',
    revision: '1',
    status: '',
    state: '',
    document_id: ''
  },
  document_revisions: [
    {
      id: '',
      change_log: '',
      filename: '',
      file_ext: '',
      revision: '1',
      status: '',
      state: '',
      document_id: ''
    }
  ]
};

export default initialDocument;
