import AddIcon from '@mui/icons-material/Add';
import { Box, IconButton, Tooltip } from '@mui/material';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import documentStatus from 'assets/constants/document-status';
import documentType from 'assets/constants/document-type';
import fieldLabel from 'assets/constants/fieldLabel';
import eventBus from 'helpers/event-bus-helper';
import { getObjectKeyValue } from 'helpers/object-field-helper';
import { DocumentsListEntity } from 'types/document-types';

const emailAttachmentColumns: GridColDef[] = [
  {
    headerName: '',
    field: 'action',
    sortable: false,
    flex: 0.6,
    minWidth: 50,
    maxWidth: 50,
    renderCell: ({ row }: GridRenderCellParams<DocumentsListEntity>) => (
      <>
        <IconButton
          color="primary"
          onClick={() => {
            eventBus.dispatch('ATTACH_DOCS', {
              id: row.id,
              document_name: row.document_name
            });
          }}
        >
          <AddIcon />
        </IconButton>
      </>
    )
  },
  {
    headerName: fieldLabel.documentName,
    field: 'document_name',
    sortable: true,
    flex: 1,
    minWidth: 350,
    renderCell: ({ row }: GridRenderCellParams<DocumentsListEntity>) => {
      return (
        <Box
          style={{
            whiteSpace: 'pre-wrap',
            wordBreak: 'break-word',
            overflowWrap: 'break-word'
          }}
        >
          <Tooltip title={row.pdf_template_name}>
            <span>{row.document_name}</span>
          </Tooltip>
        </Box>
      );
    }
  },

  {
    headerName: fieldLabel.documentType,
    field: 'category_id',
    sortable: false,
    flex: 1,
    minWidth: 150,
    renderCell: ({ row }: GridRenderCellParams<DocumentsListEntity>) => {
      return getObjectKeyValue(documentType, row.category_id);
    }
  },
  {
    headerName: 'status',
    field: 'status_id',
    sortable: true,
    flex: 0.6,
    minWidth: 120,
    renderCell: ({ row }: GridRenderCellParams<DocumentsListEntity>) => {
      return getObjectKeyValue(documentStatus, row.status_id);
    }
  }
];

export default emailAttachmentColumns;
